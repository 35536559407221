var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dataReady ? _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": _vm.closeModal
    }
  }, [_c('ExpensesModal', {
    attrs: {
      "costTypes": _vm.costTypes,
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "existingItemModal": _vm.modalData.existingItem,
      "elementId": _vm.modalData.existingItemId
    },
    on: {
      "close": _vm.closeModal
    }
  })], 1) : _vm._e(), _c('v-form', [_c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          attrs: {
            "data-cy": "salaryCosts"
          }
        }, [_vm._v(" Lønnskostnader ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk i lønnskostnader",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _vm.canUpsert ? _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "openNewExpense"
          },
          on: {
            "click": _vm.openNewExpense
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Ny lønnskostnad ")], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 1923350676)
  }, [[_c('BaseTableFiltered', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.costHeaders,
      "items": _vm.costList,
      "filter": _vm.filter,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "item.costTypeId",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_c('span', {
          staticClass: "capitalize"
        }, [_vm._v(_vm._s(_vm.getExpenseTypeById(value, _vm.costTypes)))])];
      }
    }, {
      key: "item.price",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value.toString().replace(".", ",") + " kr") + " ")];
      }
    }, {
      key: "item.isActive",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "displayExpense"
                },
                on: {
                  "click": function click($event) {
                    return _vm.displayExpense(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("vis lønnskostnad")])]), _vm.canUpsert ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "editExpense"
                },
                on: {
                  "click": function click($event) {
                    return _vm.editExpense(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger lønnskostnad")])]) : _vm._e()];
      }
    }], null, true)
  })]], 2)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }